html,
body,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
img,
pre,
form,
fieldset {
  margin: 0;
  padding: 0;
}
img,
fieldset {
  border: 0;
}
body,
html {
  height: 100%;
  width: 100%;
}
body {
  background-color: #FFF;
  color: #172B4D;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857142857143;
  letter-spacing: -0.005em;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  text-decoration-skip: ink;
}
p,
ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
form,
table {
  margin: 12px 0 0 0;
}
a {
  color: #0052CC;
  text-decoration: none;
}
a:hover {
  color: #0065FF;
  text-decoration: underline;
}
a:active {
  color: #0747A6;
}
a:focus {
  outline: 2px solid #4C9AFF;
  outline-offset: 2px;
}
h1 {
  font-size: 2.07142857em;
  font-style: inherit;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.10344828;
  margin-top: 40px;
}
h2 {
  font-size: 1.71428571em;
  font-style: inherit;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.16666667;
  margin-top: 28px;
}
h3 {
  font-size: 1.42857143em;
  font-style: inherit;
  font-weight: 500;
  letter-spacing: -0.008em;
  line-height: 1.2;
  margin-top: 28px;
}
h4 {
  font-size: 1.14285714em;
  font-style: inherit;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.006em;
  margin-top: 24px;
}
h5 {
  font-size: 1em;
  font-style: inherit;
  font-weight: 600;
  line-height: 1.14285714;
  letter-spacing: -0.003em;
  margin-top: 16px;
}
h6 {
  color: #5E6C84;
  font-size: 0.85714286em;
  font-weight: 600;
  line-height: 1.33333333;
  margin-top: 20px;
  text-transform: uppercase;
}
ul,
ol,
dl {
  padding-left: 40px;
}
[dir="rtl"]ul,
[dir="rtl"]ol,
[dir="rtl"]dl {
  padding-left: 0;
  padding-right: 40px;
}
dd,
dd + dt,
li + li {
  margin-top: 4px;
}
ul ul:not(:first-child),
ol ul:not(:first-child),
ul ol:not(:first-child),
ol ol:not(:first-child) {
  margin-top: 4px;
}
p:first-child,
ul:first-child,
ol:first-child,
dl:first-child,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
blockquote:first-child,
pre:first-child,
form:first-child,
table:first-child {
  margin-top: 0;
}
blockquote,
q {
  color: inherit;
}
blockquote {
  border: none;
  padding-left: 40px;
}
[dir="rtl"] blockquote {
  padding-left: 0;
  padding-right: 40px;
}
blockquote::before,
q:before {
  content: "\201C";
}
blockquote::after,
q::after {
  content: "\201D";
}
blockquote::before {
  float: left;
  margin-left: -1em;
  text-align: right;
  width: 1em;
}
[dir="rtl"] blockquote::before {
  float: right;
  margin-right: -1em;
  text-align: left;
}
blockquote > :last-child {
  display: inline-block;
}
small {
  color: #5E6C84;
  font-size: 0.85714286em;
  font-weight: normal;
  line-height: 1.33333333;
  margin-top: 16px;
}
code,
kbd {
  font-family: "SFMono-Medium", "SF Mono", "Segoe UI Mono", "Roboto Mono", "Ubuntu Mono", Menlo, Courier, monospace;
}
var,
address,
dfn,
cite {
  font-style: italic;
}
abbr {
  border-bottom: 1px #ccc dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  width: 100%;
}
thead,
tbody,
tfoot {
  border-bottom: 2px solid #DFE1E6;
}
td,
th {
  border: none;
  padding: 4px 8px;
  text-align: left;
}
th {
  vertical-align: top;
}
td:first-child,
th:first-child {
  padding-left: 0;
}
td:last-child,
th:last-child {
  padding-right: 0;
}
caption {
  font-size: 1.42857143em;
  font-style: inherit;
  font-weight: 500;
  letter-spacing: -0.008em;
  line-height: 1.2;
  margin-top: 28px;
  margin-bottom: 8px;
  text-align: left;
}
template {
  display: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}
@-moz-document url-prefix() {
  img {
    font-size: 0;
  }
  img:-moz-broken {
    font-size: inherit;
  }
}
.assistive {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
